import { useEffect } from "react";
import { usePlainCss } from ".";
import { useBanner } from "../contexts";
/**
 * Pass an HTML refs and we will provide a root stylesheet with the height dimensions.
 */

function useBannerRenderedHeightsAsCssVars(element, name) {
  var _useBanner = useBanner(),
      pageRequestUuid4 = _useBanner.pageRequestUuid4; // We do directly modify the inline CSS as we do not want to trigger a rerender for the complete banner content


  var cssId = usePlainCss("", "useBannerRenderedHeightsAsCssVars-".concat(name));
  var cssElement = document.getElementById(cssId);
  useEffect(function () {
    var fn = function fn(height) {
      cssElement.innerHTML = "#".concat(pageRequestUuid4, "{--rendered-height-").concat(name, ": ").concat(Math.floor(height), "px;--rendered-height-raw-").concat(name, ": ").concat(height, "px}");
    };

    if (!element) {
      return function () {// Silence is golden.
      };
    }

    if (window.ResizeObserver) {
      var ro = new ResizeObserver(function () {
        //fn(entries[0].borderBoxSize[0].blockSize); This implementation is currently broken as every browser handles the properties in another way
        fn(element.getBoundingClientRect().height);
      });
      ro.observe(element);
      return function () {
        ro.disconnect();
      };
    } else {
      var previousHeight = 0;
      var interval = setInterval(function () {
        var currentHeight = element.getBoundingClientRect().height;

        if (previousHeight !== currentHeight) {
          previousHeight = currentHeight;
          fn(currentHeight);
        }
      }, 150);
      return function () {
        clearInterval(interval);
      };
    }
  }, [element, name]);
}

export { useBannerRenderedHeightsAsCssVars };