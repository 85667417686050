import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import { Banner } from "./banner";
import { getOtherOptionsFromWindow } from "../utils";
import { BannerContextFactory } from "../contexts";
import { useWebsiteBannerOverlay, useBannerActions, useBannerPreDecisionGateway, useTcf } from "../hooks";
import { applyConsent, getUserDecision, getDefaultDecision } from "../others";
import { prepareTcfString, restoreTcf } from "../tcf";
/**
 * Simply connects a context store to the banner itself. On the website itself
 * it should not rely
 */

var WebsiteBanner = function WebsiteBanner(_ref) {
  var poweredLink = _ref.poweredLink;
  var userDecision = getUserDecision();

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.customizeValuesBanner,
      layout = _getOtherOptionsFromW2.layout,
      decision = _getOtherOptionsFromW2.decision,
      legal = _getOtherOptionsFromW2.legal,
      design = _getOtherOptionsFromW2.design,
      headerDesign = _getOtherOptionsFromW2.headerDesign,
      bodyDesign = _getOtherOptionsFromW2.bodyDesign,
      footerDesign = _getOtherOptionsFromW2.footerDesign,
      texts = _getOtherOptionsFromW2.texts,
      individualLayout = _getOtherOptionsFromW2.individualLayout,
      saveButton = _getOtherOptionsFromW2.saveButton,
      group = _getOtherOptionsFromW2.group,
      individualTexts = _getOtherOptionsFromW2.individualTexts,
      customCss = _getOtherOptionsFromW2.customCss,
      mobile = _getOtherOptionsFromW2.mobile,
      pageId = _getOtherOptionsFromW.pageId,
      pageRequestUuid4 = _getOtherOptionsFromW.pageRequestUuid4,
      pageIdToPermalink = _getOtherOptionsFromW.pageIdToPermalink,
      consentForwardingExternalHosts = _getOtherOptionsFromW.consentForwardingExternalHosts,
      essentialGroup = _getOtherOptionsFromW.essentialGroup,
      isTcf = _getOtherOptionsFromW.isTcf,
      isEPrivacyUSA = _getOtherOptionsFromW.isEPrivacyUSA,
      isAgeNotice = _getOtherOptionsFromW.isAgeNotice,
      tcf = _getOtherOptionsFromW.tcf,
      tcfMetadata = _getOtherOptionsFromW.tcfMetadata,
      groups = _getOtherOptionsFromW.groups,
      isRefreshSiteAfterConsent = _getOtherOptionsFromW.isRefreshSiteAfterConsent; // TCF compatibility


  var tcfObject = useTcf(isTcf, tcf, tcfMetadata); // Server-side rendered overlay handler

  var _useWebsiteBannerOver = useWebsiteBannerOverlay(),
      toggleOverlay = _useWebsiteBannerOver.toggleOverlay; // Context values preparation


  var _useState = useState({
    layout: _objectSpread({}, layout),
    decision: _objectSpread({}, decision),
    legal: _objectSpread({}, legal),
    design: _objectSpread({}, design),
    headerDesign: _objectSpread({}, headerDesign),
    bodyDesign: _objectSpread({}, bodyDesign),
    footerDesign: _objectSpread({}, footerDesign),
    texts: _objectSpread({}, texts),
    individualLayout: _objectSpread({}, individualLayout),
    saveButton: _objectSpread({}, saveButton),
    group: _objectSpread({}, group),
    individualTexts: _objectSpread({}, individualTexts),
    customCss: _objectSpread({}, customCss),
    mobile: _objectSpread({}, mobile),
    pageRequestUuid4: pageRequestUuid4,
    pageIdToPermalink: pageIdToPermalink,
    consentForwardingExternalHosts: consentForwardingExternalHosts,
    pageId: pageId,
    isTcf: isTcf,
    ePrivacyUSA: isEPrivacyUSA,
    ageNotice: isAgeNotice,
    tcf: tcfObject,
    tcfFilterBy: "legInt",
    updateTcfFilterBy: function updateTcfFilterBy(newFilter) {
      setContextValue(function (state) {
        return _objectSpread(_objectSpread({}, state), {}, {
          tcfFilterBy: newFilter
        });
      });
    },
    groups: groups,
    essentialGroup: essentialGroup,
    poweredLink: poweredLink,
    visible: false,
    animationVisible: true,
    skipOverlay: true,
    previewCheckboxActiveState: false,
    individualPrivacyOpen: false,
    updateIndividualPrivacyOpen: function updateIndividualPrivacyOpen(opened) {
      setContextValue(function (state) {
        return _objectSpread(_objectSpread({}, state), {}, {
          individualPrivacyOpen: opened
        });
      });
    },
    consent: {
      groups: _objectSpread(_objectSpread({}, userDecision === false ? {} : userDecision.consent), getDefaultDecision(userDecision === false))
    },
    didGroupFirstChange: false,
    updateGroupChecked: function updateGroupChecked(id, checked) {
      setContextValue(function (state) {
        var newConsent = JSON.parse(JSON.stringify(state.consent));

        if (checked) {
          newConsent.groups[id] = state.groups.filter(function (group) {
            return group.id === id;
          })[0].items.map(function (_ref2) {
            var id = _ref2.id;
            return id;
          });
        } else {
          delete newConsent.groups[id];
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          consent: newConsent,
          didGroupFirstChange: true
        });
      });
    },
    updateCookieChecked: function updateCookieChecked(groupId, id, checked) {
      setContextValue(function (state) {
        var newConsent = JSON.parse(JSON.stringify(state.consent)); // Initially create "selected" cookies

        // Initially create "selected" cookies
        if (!newConsent.groups[groupId]) {
          newConsent.groups[groupId] = [];
        }

        var cookieIds = newConsent.groups[groupId];
        var indexOf = cookieIds.indexOf(id);

        if (checked && indexOf === -1) {
          cookieIds.push(id);
        } else if (!checked && indexOf > -1) {
          cookieIds.splice(indexOf, 1);
        } // Delete group entirely when no cookies selected


        // Delete group entirely when no cookies selected
        if (!cookieIds.length) {
          delete newConsent.groups[groupId];
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          consent: newConsent
        });
      });
    },
    onSave: function onSave(markAsDoNotTrack, buttonClicked) {
      setContextValue(function (state) {
        var _state$tcf;

        // TCF compatibility: persist TCF string
        var tcfString;

        if (process.env.IS_TCF === "1" && isTcf && (_state$tcf = state.tcf) !== null && _state$tcf !== void 0 && _state$tcf.model) {
          tcfString = prepareTcfString(state.tcf.model, buttonClicked);
        }

        var appliedConsent = applyConsent({
          consent: state.consent,
          markAsDoNotTrack: markAsDoNotTrack,
          buttonClicked: buttonClicked,
          tcfString: tcfString
        });

        if (isRefreshSiteAfterConsent || state.refreshSiteAfterSave) {
          appliedConsent.then(function () {
            return (// Fix iOS / Safari bug when we want to reload the page after a POST request
              setTimeout(function () {
                return window.location.reload();
              }, state.refreshSiteAfterSave ||
              /* BC */
              2000)
            );
          }); // When refreshing the site, do not show any out animation

          // When refreshing the site, do not show any out animation
          return state;
        } else {
          return _objectSpread(_objectSpread({}, state), toggleOverlay(state, false));
        }
      });
    },
    onClose: function onClose() {
      setContextValue(function (state) {
        return _objectSpread(_objectSpread(_objectSpread({}, state), toggleOverlay(state, false)), {}, {
          // This should be set always by you again as it is one-time
          refreshSiteAfterSave: false
        });
      });
    },
    setHistory: function setHistory(history) {
      setContextValue(function (state) {
        return _objectSpread(_objectSpread({}, state), {}, {
          history: history
        });
      });
    },
    selectHistoryEntry: function selectHistoryEntry(groups, consentGroups, tcf) {
      setContextValue(function (state) {
        var additionalChanges = {
          // In case we previously used non-TCF functionality, do not show the TCF banner
          isTcf: !!tcf,
          tcf: null
        };

        if (process.env.IS_TCF === "1" && tcf) {
          Object.assign(additionalChanges, {
            tcf: "gvl" in tcf ? tcf : restoreTcf(tcf)
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          groups: groups,
          consent: _objectSpread(_objectSpread({}, state.consent), {}, {
            groups: consentGroups
          })
        }, additionalChanges);
      });
    }
  }),
      _useState2 = _slicedToArray(_useState, 2),
      contextValue = _useState2[0],
      setContextValue = _useState2[1]; // Directly modify the context value as the actions are memorized and should not trigger a rerendering


  useBannerActions(setContextValue, toggleOverlay);
  useBannerPreDecisionGateway(contextValue, setContextValue, toggleOverlay);
  var BannerContext = BannerContextFactory.Context();
  return h(BannerContext.Provider, {
    value: contextValue
  }, h(Banner, null));
};

export { WebsiteBanner };